function adResize($adWrapper, maxAdWidth) {
    $adWrapper.each(function(_index, item) {
        const $item = $(item);
        const wrapperWidth = $item.width();
        const wrapperPadding = parseFloat($item.css('padding-top')) + parseFloat($item.css('padding-bottom'));
        const scale = Math.min(wrapperWidth / maxAdWidth, 1);
        
        const containerSels = ['> iframe', '> .ad_container', '> [id|="dsa"][id$="-container"]'];
        const $container = $item.find(containerSels.join(','));
        
        $container.css({
            transform: 'scale(' + scale + ')',
            'transform-origin': '0 0'
        });
        
        $item.css({
            height: scale === 1 ? 'auto' : $container.outerHeight() * scale + wrapperPadding
        });
    });
}

function railAd($railAd, $adContainer, adHeight) {
    function loadedAdHeightCheck($iframe) {
        if ($iframe.height() > 0) {
            $iframe.height(adHeight);
            $adContainer.addClass('have-rail-ad');
            $adContainer.find('.ad-content').css('background', 'white');
        } else {
            $adContainer.removeClass('have-rail-ad');
        }
    }
    let $iframe = $railAd.find('iframe');
    if ($(window).width() >= 1200) {
        $iframe.on('load', function() {
            $iframe.addClass('is-loaded');
            loadedAdHeightCheck($iframe);
        });
        $iframe.attr('src', $iframe.attr('data-src'));
        setTimeout(() => {
            loadedAdHeightCheck($iframe);
        }, 5000);
    }
}

export default { adResize, railAd };