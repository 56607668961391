function addGDPR() {
    if ($('.j-gdpr-message').length) {
        $('.j-gdpr-close').click(function () {
            let time = 525600 * 60000; // one year in miliseconds
            let vdate = new Date();
            vdate.setTime(vdate.getTime() + time);
            document.cookie = 'dsal_gdprNoticeSeen=true; expires=' + vdate + '; path=/';
            $('.j-gdpr-message').hide();
        });
    }
}

export default { addGDPR };