import utils from './utils';
import '../../vendor/jquery.autocomplete';

// autocomplete functionality
function searchAutocomplete($inputWrapper) {
    let $input = $inputWrapper.find('.input-field');
    $.ajax({
        url: '/public/javascripts/autocomplete_part_numbers_compressed.js',
        mimeType: 'text/javascript',
        dataType: 'json',
        cache: true,
        beforeSend: function (jqXHR) {
            jqXHR.setRequestHeader('Cache-Control', 'public, max-age=604800');
        }
    }).done(function(data) {
        let searchdata = data.map(function(part){
            return part.mpn;
        });
        let searchmap = {};
        data.forEach(function(part) {
            searchmap[part.mpn] = part['class'];
        });
        if ($input.length) {
            //add auto complete feature to search box
            $input.autocomplete(searchdata, {
                selectFirst: false,
                holder: '#' + $inputWrapper.attr('id') + ' .autocomplete-holder',
                noRecord: '',
                max: 4,
                highlight: function(value, term) {
                    return utils.autocompleteHighlight(value, term, searchmap);
                }
            });
            //add auto submit upon selection (either by click or enter)
            $input.result(function(event) {
                $(event.target.form).submit();
            });
        }
    });
}

export default { searchAutocomplete };