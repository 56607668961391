import autocomplete from '../lib/autocomplete';
import utils from '../lib/utils';

function init($searchInputWrapper) {
    let $input = $searchInputWrapper.find('.j-part-search');
    let $error = $searchInputWrapper.find('.j-form-error');
    autocomplete.searchAutocomplete($searchInputWrapper);
    $searchInputWrapper.on('submit', function() {
        let val = $input.val();
        if (utils.validTerm(val)) {
            $input.removeClass('has-error');
            $error.addClass('hide');
            return true;
        } else {
            $input.addClass('has-error');
            $error.removeClass('hide');
            $searchInputWrapper.find('.autocomplete-list').addClass('hide');
            return false;
        }
    });
    $input.on('input', function() {
        if (!$error.hasClass('hide')) {
            $error.addClass('hide');
            $input.removeClass('has-error');
            $searchInputWrapper.find('.autocomplete-list').removeClass('hide');
        }
    });
}

export default { init };