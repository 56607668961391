/**
 * [validTerm description]
 * @param  {[type]} val [description]
 * @return {[type]}     [description]
 */
function validTerm(val) {
    if (typeof val === 'string' && val) {

        val = val.replace(/^\s+|\s+$/g, '');
        return val.length > 2;
    }

    return false;
}

/**
 * [autocompleteHighlight description]
 * @param  {[type]} value [description]
 * @param  {[type]} term  [description]
 * @param  {[type]} data  [description]
 * @return {[type]}       [description]
 */
function autocompleteHighlight(value, term, data) {
    let Class = data[value] || '',
        categoryIcon = Class ? '<span title="' + Class + '" class="category-icon j-home-category-icon  icon-' + toClassName(Class) + '"></span> ' : '';

    return categoryIcon + value.replace(new RegExp('(?![^&;]+;)(?!<[^<>]*)(' + term.replace(/([\^$()[\]{}*.+?|\\])/gi, '\\$1') + ')(?![^<>]*>)(?![^&;]+;)', 'gi'), '<strong>$1</strong>');
}

/**
 * Remove non alphabet chars from tag className
 * @param  {String} Class
 * @return {String}
 */
function toClassName(klass) {
    return klass ? klass.replace(/ /g, '').replace(/[^a-zA-Z]+/g, '').toLowerCase() : '';
}

function alignManuImg($el) {
    if (!$el) {
        return;
    }
    let $parent = $el.find('.j-manu-logo img');
    $parent.each(function(_i, img) {
        if (!$(img).length) {
            return;
        }
        const $img = $(img);
        if ($img.height() >= $img.width()) {
            $img.css({height: '100%', width: 'auto'});
        } else {
            $img.css({height: 'auto', width: '100%'});
        }
    });
}

function roundWithPrecision(number, precision) {
    const factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
}

function formatCurrency(number) {
    number = Number(number);
    return parseFloat(number).toFixed(2);
}

function scrollToEl(el) {
    $('html, body').animate({
        scrollTop: el.offset().top
    });
}

function renderTemplate(tlpSelector, variables) {
    const tplText = ie8Split($(tlpSelector).html(), /\$\{(.+?)\}/g);
    const render = function(props) {
        return function(token, idx) {
            return (idx % 2) ? props[token] : token;
        };
    };

    return $.map(tplText, render(variables)).join('');
}

function ie8Split(string, regExp) {
    if ('a~b'.split(/(~)/).length === 3) {
        return string.split(regExp);
    }

    if (!regExp.global) {
        regExp = new RegExp(regExp.source, 'g' + (regExp.ignoreCase ? 'i' : ''));
    }

    let m, str = '', arr = [];
    let i, len = string.length;

    for (i = 0; i < len; i++) {
        str += string.charAt(i);
        m = RegExp(regExp).exec(str);
        if (m) {
            arr.push(str.replace(m[0], ''));
            arr.push(m[0].replace(regExp, '$1'));
            str = '';
        }
    }

    if (str !== '') {
        arr.push(str);
    }

    return arr;
}

export default {
    validTerm,
    autocompleteHighlight,
    toClassName,
    alignManuImg,
    roundWithPrecision,
    formatCurrency,
    scrollToEl,
    renderTemplate,
    ie8Split
};